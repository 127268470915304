.input {
    @apply bg-white/30 border-b text-gray-600 border-text accent-text focus:outline-none focus:ring focus:ring-text focus:ring-opacity-20 focus:border-text focus:bg-white transition;

    &:not([type="checkbox"]):not([type="radio"]) {
        @apply w-full;
    }

    &[type="checkbox"],
    &[type="radio"] {
        @apply h-4 w-4 mr-2 border-text border-none focus:ring-0 mt-1;
    }

    &[type="radio"] {
        @apply rounded-full bg-red-500;
    }

    &--file {
        @apply file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-text/20 file:text-text hover:file:bg-text/40 transition;
    }

    &__label {
        &--required {
            @apply after:content-['*'] after:text-red-800 after:ml-1;
        }
    }

    &--error {
        @apply border-red-900;

        &list {
            @apply m-0 col-span-2 pl-4;
        }
    }
}
