.banner {
    @apply w-full aspect-[11/16] md:aspect-video relative overflow-hidden;

    &--xl {
        @apply aspect-square md:aspect-[15/10];

        .banner__content {
            @apply flex flex-col gap-6 h-full justify-end !py-8 md:!pb-[12vw];

            h2 {
                @apply text-[40px] md:text-[7.8125vw]
            }
        }

        .banner__curve {
            @apply hidden;
        }
    }

    &__content {
        @apply px-truman py-8 md:py-16 relative;

        h2 {
            @apply text-[11.45vw] leading-[0.8] font-extrabold uppercase;

            & + h3 {
                @apply md:mt-[0.5vw];
            }
        }
    }
}
