.button {

    @apply inline-block hover:w-full relative text-xl xl:text-[2.083vw] font-semibold leading-relaxed overflow-hidden text-left ;

    > div {
        @apply block
    }

    hr {
        @apply absolute bottom-0 left-0 transition-all my-0;
    }

    &--multitext {
        > div {
            @apply block line-clamp-1 transition;

            + div {
                @apply opacity-0 absolute top-0 bottom-0 w-screen;
            }
        }

        &:hover {
            > div {
                @apply opacity-100 first:opacity-0
            }
        }
    }

    &:hover {
        hr {
            @apply !w-full;
        }
    }

    &--xxl {
        @apply text-[7.8125vw] leading-[0.8] font-extrabold;
    }

    &__toggle {
        @apply relative overflow-hidden inline-block leading-tight select-none;

        &:after {
            @apply content-[''] block absolute bottom-0 w-full bg-text h-px transition -translate-x-full opacity-0;
        }

        &--active, &:hover {
            &:after {
                @apply translate-x-0  opacity-100;
            }
        }
    }
}

ul:has(.button__toggle--active) a:not(.button__toggle--active) {
    @apply opacity-60;
}
