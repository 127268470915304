@import url("https://fonts.bunny.net/css?family=poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

@import "./components/_banner.pcss" layer(components);
@import "./components/_button.pcss" layer(components);
@import "./components/_stick.pcss" layer(components);
@import "./components/_input.pcss" layer(components);

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --typography-sans: "Poppins";
    --typography-serif: "Poppins";
    --typography-heading: "Poppins";

    --color-set-background: 251 199 155;
    --color-set-text: 0 4 43;

    --color-set-taupe: 251 199 155;
    --color-set-blue: 0 4 43;


    --padding-truman: 0.729vw;
}

@layer base {

    html {
        @apply antialiased scroll-smooth;

        font-size: 16px;
    }

    h1, h2, h3, h4, blockquote, blockquote.single, .phonenumber {
        @apply text-[36px] md:text-[3.59vw] leading-[1.15] font-semibold tracking-wide;

        &.xl {
            @apply md:text-[7.8125vw] leading-[0.8];
        }
    }

    h3, blockquote, blockquote.single + div {
        @apply text-[20px] md:text-[2.083vw];
    }

    body {
        @apply bg-background text-text text-[13px] leading-[1.85] xl:text-[1.3vw] min-h-screen sm:leading-[2.4];
        cursor: default;

        * {
        //position: relative;
        }
    }

    hr {
        @apply border-text my-truman;
    }

    blockquote:not(:first-child),
    p:not(:first-of-type) {
        @apply mt-[1.625em] sm:mt-[3.125vw]
    }

    blockquote:not(.single) {
        & + p {
            @apply !mt-[1em] leading-none;
        }
    }

    footer {
        > div:last-of-type {
            .button {
                @apply font-medium text-lg xl:text-[1.3vw]
            }
        }
    }
}

@layer utilities {
    html.lenis {
        height: auto;
    }

    .lenis.lenis-smooth {
        scroll-behavior: auto;
    }

    .lenis.lenis-smooth [data-lenis-prevent] {
        overscroll-behavior: contain;
    }

    .lenis.lenis-stopped {
        overflow: hidden;
    }

    .lenis.lenis-scrolling iframe {
        pointer-events: none;
    }
}

@layer components {
    .textblock {
        ul {
            @apply list-disc ml-6;
        }

        ol {
            @apply list-decimal ml-6;
        }

        a {
            @apply underline;
        }
    }

    .zoom {

        h2 {
            @apply scale-100 origin-top-left transform duration-700;
        }

        &:hover {
            h2 {
                @apply scale-102;
            }
        }
    }
}

